export const ENDORSEMENT_ORDER = {
  DEFAULT: 'default',
  RANDOM: 'random',
  RANKED: 'ranked',
  ALPHABETICAL: 'alphabetical',
};

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const PARTY = {
  REP: 'Republican',
  DEM: 'Democratic',
  PF: 'Peace & Freedom',
  GRN: 'Green',
  NPP: 'No Party Preference',
  NP: 'Non-partisan',
  INDEP: 'Independent',
  LIB: 'Libertarian',
  AI: 'American Independent',
};

export const JURISDICTION = {
  FEDERAL: 'Federal',
  STATE: 'State',
  LOCAL: 'Local',
  OTHER: 'Other', // Not listed in TLDR
};

export const QS = {
  NOT_SENT: {
    en: 'No questionnaire sent',
    zh: '未寄送問卷',
  },
  NO_CONTACT: {
    en: 'Contact information unavailable',
    zh: '聯絡資料不可得',
  },
  NO_RESPONSE: {
    en: 'Did not return questionnaire',
    zh: '未回覆問卷',
  },
  DECLINED: {
    en: 'Declined to fill out the questionnaire',
    zh: '拒絕填寫問卷',
  },
};

export const ENDORSEMENT = {
  NO_ENDORSEMENT: 'No Endorsement',
  YES: 'Yes',
  NO: 'No',
  UNDECIDED: 'Undecided',
};

export const ENDORSEMENT_TRANSLATIONS = {
  NO_ENDORSEMENT: {
    en: 'No Endorsement',
    zh: '無決議',
  },
  YES: {
    en: 'Yes',
    zh: '贊成',
  },
  NO: {
    en: 'No',
    zh: '反對',
  },
  UNDECIDED: {
    en: 'Undecided',
    zh: '未決定',
  },
};

export const isNoEndorsement = (endorsement) => {
  for (const [lang, end] of Object.entries(ENDORSEMENT_TRANSLATIONS.NO_ENDORSEMENT)) {
    if (endorsement === end) {
      return true;
    }
  }
  for (const [lang, end] of Object.entries(ENDORSEMENT_TRANSLATIONS.UNDECIDED)) {
    if (endorsement === end) {
      return true;
    }
  }
  return false;
};

export const isYes = (endorsement) => {
  for (const [lang, end] of Object.entries(ENDORSEMENT_TRANSLATIONS.YES)) {
    if (endorsement === end) {
      return true;
    }
  }
  return false;
};

export const isNo = (endorsement) => {
  for (const [lang, end] of Object.entries(ENDORSEMENT_TRANSLATIONS.NO)) {
    if (endorsement === end) {
      return true;
    }
  }
  return false;
};
